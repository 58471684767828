import React from 'react';
import { translate } from '../../utils';
import amaris from '../../assets/img/amaris-logo.jpeg';
import careyn from '../../assets/img/Careyn.svg';
import quarijn from '../../assets/img/QuaRijn.svg';
import koperhorst from '../../assets/img/De_Koperhorst.svg';
import mariaDommer from '../../assets/img/Maria_Dommer.png';
import pietersEnBlokland from '../../assets/img/St_Pieters_en_Bloklands_gasthuis.svg';
import vredenoord from '../../assets/img/Woonzorggroep_Vredenoord.svg';
import warande from '../../assets/img/Warande.svg';
import wulverhorst from '../../assets/img/De_Wulverhorst.svg';
import zorggroepMarijke from '../../assets/img/Zorggroep_Marijke.svg';
import deHaven from '../../assets/img/logo_de_haven.jpg';
import kingArthur from '../../assets/img/Logo_King_Arthur_Groep_web.jpg';
import moriaHoeve from '../../assets/img/moriahoeve.png';
import logoTopaz from '../../assets/img/logotopaz.png';
import logoBartHuis from '../../assets/img/logo_bartholomeus_gasthuis.png';
import zzp from '../../assets/img/zzp-ers.png';
import jahjireh from '../../assets/img/jahjireh-logo.jpeg';
import grootHoogwaak from '../../assets/img/ghk-logo.jpeg';
import wijdezorg from '../../assets/img/wijdezorg_logo.jpeg';
import aangenaamMenno from '../../assets/img/aangenaam-menno-logo.png';
import hollandZorggroep from '../../assets/img/holland_zorggroep_logo.png';
import Roomburgh from '../../assets/img/logo-roomburgh.png';
import ProSenectute from '../../assets/img/pro-senectute-logo.jpeg';
import ZorgLuwte from '../../assets/img/in-de-luwte-logo.png';
import ZorgvanToen from '../../assets/img/logo_zorgvantoen.png';
import { Link } from 'react-router-dom';

export default function ({}) {
  const others = [
    // { logo: mariaDommer, label: translate('ENTRY__MARIA_DONNER') },
    {
      logo: pietersEnBlokland,
      label: translate('ENTRY__PIETERS_EN_BLOKLAND'),
    },
    { logo: vredenoord, label: translate('ENTRY__VREDENOORD') },
    { logo: wulverhorst, label: translate('ENTRY__WULVERHORST') },
    { logo: zorggroepMarijke, label: translate('ENTRY__ZORGGROEP_MARIJKE') },
    {
      logo: kingArthur,
      label: translate('ENTRY__KINGARTHUR_GROEP'),
    },
    { logo: moriaHoeve, label: translate('ENTRY__MORIAHOEVE') },
    { logo: logoBartHuis, label: translate('ENTRY__BARTHOLOMEUS_GASTHUIS') },
    { logo: jahjireh, label: translate('ENTRY__JAHJIREH') },
    {
      logo: grootHoogwaak,
      label: translate('ENTRY__GROOT_HOOGWAAK'),
    },
    {
      logo: aangenaamMenno,
      label: translate('ENTRY__AANGENAAM_BYMENNO'),
    },
    {
      logo: hollandZorggroep,
      label: translate('ENTRY__HOLLAND_ZORGGROEP'),
    },
    {
      logo: Roomburgh,
      label: translate('ENTRY__ROOMBURGH'),
    },
    {
      logo: ZorgLuwte,
      label: translate('ENTRY__IN_DE_LUWTE'),
    },
    {
      logo: ZorgvanToen,
      label: translate('ENTRY__ZORG_VAN_TOEN'),
    },
  ];
  const today = new Date();
  const deHavenOfflineDate = new Date(2022, 7, 11); // as of 11 aug 2022 0:00:00
  const deHavenBackOnlineDate = new Date(2022, 8, 1); // as of 1 sept 2022 0:00:00
  const warandeOfflineDate = new Date(2023, 5, 12); // as of 12 jun 2023 0:00:00
  const warandeBackOnlineDate = new Date(2023, 6, 3); // as of 3 jul 2023 0:00:00
  const mariaDommerOfflineDate = new Date(2023, 5, 12); // as of 12 jun 2023 0:00:00
  const mariaDommerBackOnlineDate = new Date(2023, 6, 10); // as of 10 jul 2023 0:00:00
  return (
    <div className="content">
      <div>
        <h2 className="heading">{translate('ENTRY__TITLE')}</h2>
        <p
          className="instruction"
          dangerouslySetInnerHTML={{ __html: translate('ENTRY__TEXT') }}
        ></p>
        <div className="sso-providers">
          <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/7086e82e-28bb-436b-882a-32a37721b179/&next=/">
            <img src={careyn} />
          </a>
          <a href="https://rein.platform.co.nl/saml2/login/?idp=http://sts.ecumulus.nl/adfs/services/trust&next=/">
            <img src={quarijn} />
          </a>
          <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/7017e797-cee2-4374-8418-0d75a5fdd84a/&next=/">
            <img src={koperhorst} />
          </a>
          <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/d55b2eb8-6cd3-40ae-9da3-28f29f90fa7a/&next=/">
            <img src={logoTopaz} />
          </a>
          <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/619e824b-97e8-4ee6-af98-42c0b2553c6f/&next=/">
            <img src={amaris} />
          </a>
          <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/82114e6a-0847-4b81-b269-4344da7f9c47/&next=/">
            <img src={wijdezorg} />
          </a>
          <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/85268018-1f18-40d0-8cd0-e4ea2a5a991f/&next=/">
            <img src={ProSenectute} />
          </a>
          {deHavenOfflineDate < today && today < deHavenBackOnlineDate ? (
            <a
              onClick={() => {
                alert(
                  `De Haven krijgt binnenkort een vernieuwde digitale leeromgeving. Daarom is REIN tijdelijk dicht en is inloggen niet mogelijk. Heb je vragen? Neem dan contact op met: praktijkopleiding@dehaven.nu.`
                );
                return false;
              }}
            >
              <img src={deHaven} />
            </a>
          ) : (
            <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/9cef9247-ef1a-4ade-b3ba-19b095e94a64/&next=/">
              <img src={deHaven} />
            </a>
          )}
          {warandeOfflineDate < today && today < warandeBackOnlineDate ? (
            <a
              onClick={() => {
                alert(
                  `We gaan over naar één leerportaal: REIN! Daarom is REIN vanaf maandag 12 juni tijdelijk niet bereikbaar. Vanaf 3 juli kun je weer alles op het gebied van Leren & Ontwikkelen terug vinden in REIN. Tot dan! Voor vragen kun je terecht bij academie@warandeweb.nl.`
                );
                return false;
              }}
            >
              <img src={warande} />
            </a>
          ) : (
            <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/9d1f0381-1474-4795-89e1-9a96f27d133f/&next=/">
              <img src={warande} />
            </a>
          )}
          {mariaDommerOfflineDate < today &&
          today < mariaDommerBackOnlineDate ? (
            <a
              onClick={() => {
                alert(
                  `REIN is op dit moment tijdelijk gesloten voor gebruik. Vanaf maandag 10 juli is REIN weer beschikbaar.`
                );
                return false;
              }}
            >
              <img src={mariaDommer} />
            </a>
          ) : (
            <a href="https://rein.platform.co.nl/saml2/login/?idp=https://sts.windows.net/d27e2e87-a02f-4f55-8bbf-569a2fe756ba/&next=/">
              <img src={mariaDommer} />
            </a>
          )}
          {others
            .filter((item) => !item.hide)
            .map(({ logo, label }, i) => (
              <Link key={i} to="/portal/register-or-login">
                <img src={logo} />
              </Link>
            ))}

          <a href="https://ikbenrein.info/mijn-account/?redirect_to=%2Findex.php%3Faction%3Dikbenrein-portal">
            <img src={zzp} />
          </a>
          <Link to="/portal/register-or-login">
            <b>{translate('ENTRY__OVERIG')}</b>
          </Link>
        </div>
      </div>
    </div>
  );
}
